/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    object, bool, string, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import loadable from '@loadable/component';
import PanelT3 from '../PanelT3';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import { getIsBot, getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getPresentationFamily, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import { getCurrentPageView } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { emitCustomTrackingEvent } from '../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

const HeroSingleResponsive = loadable(() => import(/* webpackChunkName: "HeroSingleResponsive" */  './HeroSingleResponsive'));
const HeroStory = loadable(() => import(/* webpackChunkName: "HeroStory" */  '../HeroStory/HeroStory'));
const HeroDepartment = loadable(() => import(/* webpackChunkName: "HeroDepartment" */  '../HeroDepartment/HeroDepartment'));
const HeroSingleDesktop = loadable(() => import(/* webpackChunkName: "HeroSingleDesktop" */  './Desktop/HeroSingleDesktop'));
const HeroSingleMobile = loadable(() => import(/* webpackChunkName: "HeroSingleMobile" */  './Mobile/HeroSingleMobile'));
const HeroSingleV2 = loadable(() => import(/* webpackChunkName: "HeroSingle_V2" */  '../HeroSingle_V2/Responsive/HeroSingle_V2'));

const useStyles  = makeStyles((theme) => ({
    foodBlock: {
        position: 'relative',
        margin: '0 auto 2rem',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 1rem',
        },
    },
    foodBlockWithGrid: {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
    },
    floralBlock: ({ height, pageView }) => ({
        [theme.breakpoints.up(1366)]: {
            width: '100%',
            height: `${height || 400}px`,
            margin: pageView?.pageType === 'home' ? 0 : '0 auto 2rem',
        },
    }),
    floralBlockWithGrid: ({ height, pageView }) => ({
        [theme.breakpoints.up(1366)]: {
            width: '100%',
            height: `${height || 400}px`,
            margin: pageView?.pageType === 'home' ? 0 : '0 auto',
        },
    }),
}));

const Hero = ({
    data: blockData, isBot, ssrDeviceType, presentationFamily, salesforceResponse, setShowSkeleton,
}) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        includeRefs: ['reference.message_collection.link_list.reference'],
        newDataSelector: (dta) => dta?.findContentByUID?.content?.entry || data,
    });

    const pageView = useSelector(getCurrentPageView);
    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    if (loading) {
        if (ssrDeviceType.toLowerCase() === 'mobile' && blockData?.mobile_skeleton) {
            return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
        }
        if (ssrDeviceType.toLowerCase() === 'desktop' && blockData?.desktop_skeleton) {
            return <GenericSkeleton height={blockData?.skeleton_height} />;
        }
    }

    if (!data) return <></>;
    if (data?.reference?.[0]?.['_content_type_uid'] === 'herosingle_v2') return <HeroSingleV2 data={data} />;
    if (data?.reference?.[0]?.['_content_type_uid'] === 'hero_story') return <HeroStory data={data} />;
    if (data?.reference?.[0]?.['_content_type_uid'] === 'hero_single_responsive') { return <HeroSingleResponsive data={data?.reference?.[0]} />; }
    if (data?.reference?.[0]?.['_content_type_uid'] === 'department_hero') return <HeroDepartment data={data} />;

    let block = null;
    if (data && !data?.reference?.[0]?.reference?.[0]) {
        block = data;
    } else if (data || data?.reference?.[0]?.reference?.[0]) {
        block = data.reference[0];
    }

    if (!block || !block?.reference?.[0]) {
        return null;
    }
    const {
        presentation_type = '',
        height = '',
        reference = '',
        mobile_department_heading = '',
        unique_selector = '',
        device,
        tablet_x_offset = '',
        message_location = '',
        restrict_message_margins = false,
        mobile_height = '',
        block_margin = {},
    } = block;

    const {
        linking = {},
        is_mobile_copy = false,
        message_collection = [],
        message_styling = [],
        image = {},
        image_mobile = {},
        image_tablet = {},
        overlays = {},
        use_mobile_image_for_tablet = false,
        uid = '',
        container_attributes = [],
        image_opacity = null,
        message_positioning = null,
        video = {},
    } = reference[0];
    const classes = useStyles({ height, pageView });

    const dataUniqueId = uniqueId(unique_selector);
    if (data?.reference?.[0]?.reference?.[0]?._content_type_uid === 't3_panel') {
        return (
            <div className={classes.block} data-uniqueid={dataUniqueId} cmsuid={uid}>
                <PanelT3 data={data.reference[0]} />
            </div>
        );
    }
    const mobile_height_pixel = block?.mobile_height_pixel || '';
    const dispatch = useDispatch();

    const trackCustomClickstreamEventByType = (actionType = 'Impression') => {
        if (salesforceResponse?.campaign) {
            dispatch(emitCustomTrackingEvent({
                action: actionType,
                salesforceResponse,
                page: { type: 'home' },
                eventSubType: 'campaign-tracking',
            }));
        }
    };
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const foodBlockCssStyle = ffHasHomepageGridStructure ? classes.foodBlockWithGrid : classes.foodBlock;
    const floralBlockCssStyle = ffHasHomepageGridStructure ? classes.floralBlockWithGrid : classes.floralBlock;
    const videoData = data?.reference?.[0]?.reference?.[0]?.video;
    return (
        <div
            className={presentationFamily === 'food' ? `hero_block ${foodBlockCssStyle}` : `${floralBlockCssStyle}`}
            data-uniqueid={dataUniqueId}
            cmsuid={uid}
        >
            {ssrDeviceType === 'mobile'
               && (device === 'Both' || device === 'Mobile')
               && (
                   <HeroSingleMobile
                       data={data}
                       image_mobile={image_mobile}
                       message_collection={message_collection}
                       linking={linking}
                       mobile_department_heading={mobile_department_heading}
                       presentation_type={presentation_type}
                       mobile_height={mobile_height}
                       is_mobile_copy={is_mobile_copy}
                       container_attributes={container_attributes}
                       blockMargin={block_margin}
                       messagePositioning={message_positioning}
                       imageOpacity={image_opacity}
                       mobile_height_pixel={mobile_height_pixel}
                       videoPoster={data?.reference?.[0]?.reference?.[0]?.video_poster || {}}
                       videoData={videoData}
                       video={video}
                       trackCustomClickstreamEventByType={trackCustomClickstreamEventByType}
                   />
               )}

            {ssrDeviceType !== 'mobile'
               && (device === 'Both' || device === 'Desktop')
               && (
                   <HeroSingleDesktop
                       linking={linking}
                       message_collection={message_collection}
                       message_styling={message_styling}
                       message_location={message_location}
                       image={image}
                       image_mobile={image_mobile} // optional for tablet
                       presentation_type={presentation_type}
                       height={height}
                       overlays={overlays}
                       isBot={isBot}
                       tabletbg={tablet_x_offset}
                       tabletMobImage={use_mobile_image_for_tablet}
                       restrict_message_margins={restrict_message_margins}
                       container_attributes={container_attributes}
                       blockMargin={block_margin}
                       imageOpacity={image_opacity}
                       messagePositioning={message_positioning}
                       isTabletImage={image_tablet}
                       videoPoster={data?.reference?.[0]?.reference?.[0]?.video_poster || {}}
                       videoData={videoData}
                       video={video}
                       trackCustomClickstreamEventByType={trackCustomClickstreamEventByType}
                   />
               )}
        </div>
    );
};

Hero.propTypes = {
    data: object.isRequired,
    isBot: bool,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    salesforceResponse: object,
    setShowSkeleton: func,
};
Hero.defaultProps = {
    isBot: false,
    salesforceResponse: {},
    setShowSkeleton: () => {},
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
});

const enhance = compose(
    connect(mapStateToProps, null),
);
export default enhance(Hero);
