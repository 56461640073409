/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// lookup given color in brand's theme
//
// TODO: replace this with lookup of actual theme in "Brand Theme Full" content type
// These are hardcoded values for Harry and David
export default function themeColorLookup(colorName) {
    const HDColors = {
        primary: '#a1001a',   // carmine
        primary_hover: '#790000',  // maroon
        primary_font_color: '#ffffff', // white
        secondary: '#2f2f2f', // night rider
        secondary_hover: '#757575', // grey
        secondary_font_color: '#ffffff', // white
        tertiary: '#ffffff', // white
        tertiary_hover: '#cccccc', // very light grey
        tertiary_font_color: '#2b2b2b', // very light grey
        heading_background_light: '#ebebeb',
        heading_background_light_copy: '#2f2f2b',
        heading_background_dark: '#2f2f2b',
        heading_background_dark_copy: '#ffffff',
    };

    return HDColors[colorName] ? HDColors[colorName] : '';
}
