/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import themeColorLookup from '../../components/ContentTypeComponents/themeColorLookup';

// Convert style object from GraphQl data into valid styles.
// Requires consistent data from Content Types
//  - Modular Block named "Styles" > Style attribute e.g. "font_size".
//    (Refer to Panel Content Type)
// UPDATE THIS: e.g.   <div class={classes.someclass} style={itemStyleOverrides}>

/**
 * JSDoc for this function
 * @param {Object} attributes Array of styles from ContentStack
 * @param {Object} asObj Boolean - Return styles as an object rather than a string (default is false; string)
 */
export default function processCss(attributes, asObj = false) {
    const styles = [];

    function sanitizeCSS(s) {
        return s?.replace(/[^-_.a-zA-Z0-9]/g, '');
    }

    function isValidCSSNumber(x) {
        if (typeof x === 'number') {
            return true;
        }

        if (typeof x === 'string') {
            return !!x.match(/(^-?\d{1,5}$)|(^-?\d{1,5}\.\d{0,5}$)|(^-?\d{0,5}\.\d{1,5}$)/);  // whole number, negative number and/or decimal number
        }

        return false;
    }
    function isNull(val) { // returns 'auto' if null or NaN
        return isValidCSSNumber(val) ? val : 'auto';
    }
    function translateUnit(unit) {
        let returnUnit;
        if (!unit) {
            return 'px';
        }
        switch (unit.toLowerCase()) {
            case 'pixel':
            case 'pixels':
                returnUnit = 'px';
                break;
            case 'percent':
            case 'percentage':
                returnUnit = '%';
                break;
            case 'ems':
            case 'em':
                returnUnit = 'em';
                break;
            case 'vw':
                returnUnit = 'vw';
                break;
            default:
                returnUnit = 'px';
        }
        return returnUnit;
    }
    function setOffset(styleObject) {
        // Set the offset values of relatively or absolutely positioned items.
        const offsetArray = Object.keys(styleObject);
        offsetArray.forEach((s) => {
            switch (s) {
                case 'top_offset':
                    styles.push(`top:${styleObject.top_offset ? styleObject.top_offset : 0}em`);
                    break;
                case 'left_offset':
                    styles.push(`left:${styleObject.left_offset ? styleObject.left_offset : 0}em`);
                    break;
                case 'right_offset':
                    styles.push(`right:${styleObject.right_offset ? styleObject.right_offset : 0}em`);
                    break;
                case 'bottom_offset':
                    styles.push(`bottom:${styleObject.bottom_offset ? styleObject.bottom_offset : 0}em`);
                    break;
                default:
            }
        });
    }
    if (attributes && typeof attributes !== 'string') {
        attributes.forEach((s) => {
            const k = Object.keys(s)[0];
            let unit = '';
            switch (k) {
                // Dimensional Styles
                case 'offset':
                    styles.push('position:relative');
                    setOffset(s[k]);
                    break;
                case 'offset_absolute':
                    styles.push('position:absolute');
                    setOffset(s[k]);
                    break;
                case 'width':
                    // define unit because there are instances where setting within contentstack would clutter UI
                    unit = s[k].unit !== undefined ? translateUnit(s[k].unit) : 'px';
                    styles.push(`width:${s[k].width}${unit}`);
                    break;
                case 'dimensions':
                    // handles both 'width' and 'height'
                    switch (s[k].unit.toLowerCase()) {
                        case 'pixels':
                        case 'vw':
                        case 'percent':
                        case 'percentage': {
                            let suffix = s[k].unit.toLowerCase();
                            if (suffix === 'pixels') {
                                suffix = 'px';
                            }
                            if (suffix === 'percent' || suffix === 'percentage') {
                                suffix = '%';
                            }
                            if (s[k].width || s[k].height) {
                                ['width', 'height'].forEach((dimension) => {
                                    if (dimension in s[k] && s[k][dimension] !== '') {
                                        if (isValidCSSNumber(s[k][dimension])) {
                                            // need to convert this to array push
                                            styles.push(`${dimension}:${s[k][dimension]}${suffix}`);
                                        }
                                    }
                                });
                            } else {
                                // Set width and style to auto if there is a unit given but no values
                                styles.push('width:auto');
                                styles.push('height:auto');
                            }
                        }
                            break;
                        default:
                            mbpLogger.logWarning({ appName: process.env.npm_package_name, message: `Unhandled ${k} unit:${sanitizeCSS(s[k].unit)}` });
                    }
                    break;

                // Alignment Styles
                case 'justify': // justify_content from Panel Message Styling
                    // styles.push(`justify-content:${s[k].justify_content.toLowerCase()}`);
                    switch (s[k].justify_content.toLowerCase()) {
                        case 'left':
                            styles.push('margin:0 auto 0 0');
                            break;
                        case 'center':
                            styles.push('margin:0 auto');
                            break;
                        case 'right':
                            styles.push('margin:0 0 0 auto');
                            break;
                        default:
                            styles.push('margin:0 auto');
                            break;
                    }
                    break;
                case 'margin':
                    unit = translateUnit(s[k].unit);
                    styles.push(`margin:${isNull(s[k].top)}${isNull(s[k].top) !== 'auto' ? unit : ''} ${isNull(s[k].right)}${isNull(s[k].right) !== 'auto' ? unit : ''} ${isNull(s[k].bottom)}${isNull(s[k].bottom) !== 'auto' ? unit : ''} ${isNull(s[k].left)}${isNull(s[k].left) !== 'auto' ? unit : ''}`);
                    break;
                case 'padding':
                    unit = translateUnit(s[k].unit);
                    styles.push(`padding:${isNull(s[k].top)}${isNull(s[k].top) !== 'auto' ? unit : ''} ${isNull(s[k].right)}${isNull(s[k].right) !== 'auto' ? unit : ''} ${isNull(s[k].bottom)}${isNull(s[k].bottom) !== 'auto' ? unit : ''} ${isNull(s[k].left)}${isNull(s[k].left) !== 'auto' ? unit : ''}`);
                    break;
                // Font/Text Styles
                case 'font_family':
                    styles.push(`${s[k].font_family}`);
                    break;
                case 'alignment':
                    styles.push(`text-align:${s[k].text_align.toLowerCase()}`);
                    break;
                case 'font_size':
                    styles.push(`font-size:${s[k].font_size}em`);
                    break;
                case 'font':
                    styles.push(s[k].font_family);
                    break;
                case 'line_height':
                    if (s[k].unit) {
                        switch (s[k].unit.toLowerCase()) {
                            case 'pixels':
                            case 'percent':
                                if (isValidCSSNumber(s[k].value)) {
                                    const suffix = s[k].unit.toLowerCase() === 'pixels' ? 'px' : '%';
                                    styles.push(`line-height:${s[k].value}${s[k].value ? suffix : ''}`);
                                }
                                break;
                            case 'em':
                                if (isValidCSSNumber(s[k].value)) {
                                    const suffix = 'em';
                                    styles.push(`line-height:${s[k].value}${s[k].value ? suffix : ''}`);
                                }
                                break;
                            default:
                                mbpLogger.logWarning({ appName: process.env.npm_package_name, component: s, message: `Unhandled ${k} unit:${sanitizeCSS(s[k].unit)}` });
                        }
                    }
                    break;
                case 'letter_spacing':
                    styles.push(`letter-spacing:${s[k].letter_spacing}em`);
                    break;
                case 'spacing':
                    if (s[k].unit) {
                        switch (s[k].unit.toLowerCase()) {
                            case 'pixels':
                                if (isValidCSSNumber(s[k].letter_spacing)) {
                                    styles.push(`letter-spacing:${s[k].letter_spacing}px`);
                                }
                                break;
                            default:
                                mbpLogger.logWarning({ appName: process.env.npm_package_name, component: s, message: `Unhandled ${k} unit:${sanitizeCSS(s[k].unit)}` });
                        }
                    }
                    break;
                case 'text_transform':
                    switch (s[k].text_transform.toLowerCase()) {
                        case 'lowercase':
                            styles.push(`text-transform:${s[k].text_transform}`);
                            break;
                        case 'uppercase':
                            styles.push(`text-transform:${s[k].text_transform}`);
                            break;
                        case 'capitalize':
                            styles.push(`text-transform:${s[k].text_transform}`);
                            break;
                        default:
                            mbpLogger.logWarning({ appName: process.env.npm_package_name, component: s, message: `Unhandled ${k} unit:${sanitizeCSS(s[k].unit)}` });
                    }
                    break;

                // Decorational
                case 'border':
                    switch (s?.[k]?.style?.toLowerCase()) {
                        case 'solid':
                        case 'dotted':
                        case 'double':
                        case 'grooved':
                        case 'ridge':
                        case 'inset':
                        case 'outset':
                            styles.push(`border-style:${s[k].style.toLowerCase()}`);
                            break;
                        default:
                            styles.push('border-style: solid');
                            mbpLogger.logWarning({ appName: process.env.npm_package_name, message: `Unhandled ${k} style:${sanitizeCSS(s[k].style)}` });
                    }
                    if (isValidCSSNumber(s[k].width)) {
                        styles.push(`border-width:${s[k].width}${s[k].width ? 'px' : ''}`);
                    }
                    switch (s[k].color.colorTheme) {
                        case 'custom':
                            styles.push(`border-color:${s[k].color.color}`);
                            break;
                        case 'none':
                            break;
                        default: {
                            const c = themeColorLookup(s[k].color.colorTheme);
                            if (c) {
                                styles.push(`border-color:${c}`);
                            }
                        }
                    }
                    break;

                case 'background_color':
                    switch (s[k].color.colorTheme) {
                        case 'custom':
                            styles.push(`background-color:${s[k].color.color}`);
                            break;
                        case 'none':
                            break;
                        default: {
                            const c = themeColorLookup(s[k].color.colorTheme);
                            if (c) {
                                styles.push(`background-color:${c}`);
                            }
                        }
                    }
                    break;

                case 'color':
                    Object.entries(s).map(([key, value]) => {
                        if ('color' in value || 'copy_color' in value) {
                            const colorKey = s[key].color ? s[key].color : s[key].copy_color;
                            switch (colorKey.colorTheme) {
                                case 'custom':
                                    styles.push(`color:${colorKey.color}`);
                                    break;
                                case 'none':
                                    break;
                                default: {
                                    const c = themeColorLookup(colorKey);
                                    if (c) {
                                        styles.push(`color:${c}`);
                                    }
                                }
                            }
                        }
                        if ('background_color' in value) {
                            switch (s[key].background_color.colorTheme) {
                                case 'custom':
                                    styles.push(`background-color:${s[k].background_color.color}`);
                                    break;
                                case 'none':
                                    break;
                                default: {
                                    const c = themeColorLookup(s[k].background_color.colorTheme);
                                    if (c) {
                                        styles.push(`background-color:${c}`);
                                    }
                                }
                            }
                        }
                        return null; // sorry Dan
                    });
                    break;
                case 'circle_mask':
                    if (s[k].mask) {
                        styles.push('border-radius: 50%');
                    }
                    break;

                default:
                    mbpLogger.logWarning({ appName: process.env.npm_package_name, message: `Unhandled container attribute:${sanitizeCSS(k)}` });
            }
        });

        if (asObj && attributes.length) {
            const thisComponent = 'generateValidCss';

            // in case user passed a css string as attribute into generateValidCss()
            const s = styles.length ? styles.join(';') : attributes;
            if (s !== undefined && typeof s === 'string') {
                const obj = {};
                const arr = s.split(';');
                arr.forEach((i) => {
                    const j = i.split(':');
                    obj[j[0]] = j[1];
                });
                return obj;
            }
            mbpLogger.logWarning({ appName: process.env.npm_package_name, component: thisComponent, message: `Error converting inline style into object: '${s}'` });
            return {};
        }
    }
    if (typeof attributes === 'string' && asObj === undefined) {
        mbpLogger.logWarning({ appName: process.env.npm_package_name, scomponent: 'generateValidCss', message: `Attributes were a string initially: '${attributes}'` });
        styles.push(attributes);
    }
    if (!attributes || (!attributes.length && asObj)) {
        return {};
    }
    return styles.join(';');
}
