/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { array, func } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import ReactMarkdown from 'react-markdown';
import generateValidCss from '../../helpers/contentstack/generateValidCss';
import DesktopMobile from '../../helpers/DesktopMobile/DesktopMobile';
import { trackEvent } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },

    // ////////////////
    // Desktop Styles
    // ////////////////
    heroContainer: ({ data }) => ({
        display: 'flex',
        maxWidth: '1400px',
        margin: '0 auto',
        minHeight: data.height ? `${data.height}px` : '310px',
    }),
    image: ({ data }) => ({
        backgroundImage: `url(${data.reference[0].image?.url}?auto=webp)`,
        backgroundSize: 'cover',
        order: data.message_location === 'Left' ? '2' : '1',
    }),
    messageContainer: ({ data }) => ({
        // todo: write test for element ordering
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        order: data.message_location === 'Left' ? '1' : '2',
        backgroundColor: data.reference[0].message_styling[0]?.background_color?.color?.color || '#FFFFFF',
        width: generateValidCss(data.reference[0]?.message_styling, true).width,
        margin: generateValidCss(data.reference[0]?.message_styling, true).margin,
        padding: generateValidCss(data.reference[0]?.message_styling, true).padding,
    }),

    // ////////////////
    // Mobile Styles
    // ////////////////
    heroContainerMobile: () => ({
        width: '100%',
    }),
    imageMobileContainer: () => ({
        height: '216px',
        margin: '30px auto 0',
        position: 'relative',
    }),
    imageBgPanelMobile: ({ data }) => ({
        height: '150px',
        width: '100%',
        zIndex: '0',
        position: 'absolute',
        bottom: '0',
        backgroundColor: data.reference[0].message_styling[0]?.background_color?.color?.color || '#FFFFFF',
    }),
    imageMobile: ({ data }) => ({
        backgroundImage: `url(${data.reference[0].image?.url}?auto=webp)`,
        backgroundSize: 'cover',
        height: '210px',
        position: 'absolute',
        top: '1px',
        left: '2.5%',
        margin: '0 auto',
        width: '95%',
        zIndex: '10',
    }),
    messageContainerMobile: ({ data }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: data.reference[0].message_styling[0]?.background_color?.color?.color || '#FFFFFF',
        width: '100%',
        margin: generateValidCss(data.reference[0]?.message_styling, true).margin,
        padding: generateValidCss(data.reference[0]?.message_styling, true).padding,
    }),
    cta: {
        display: 'flex',
        alignItems: 'center',
    },
    ctaIconContainer: {
        marginLeft: '15px',
    },
}));

const PanelT3 = ({ data, track }) => {
    if (data && data.reference[0]) {
        const classes = useStyles({ data });

        const renderCtaIcon = (ctaData) => {
            switch (ctaData.icon_type) {
                case 'T3 Right Arrow':
                    return <ArrowForwardIcon fontSize="large" />;
                case 'Solid Triangle':
                    return <ArrowRight iconStyle={classes.ctaIcon} />;
                case 'Caret':
                    return <ChevronRightIcon iconStyle={classes.ctaIcon} />;
                default:
                    return <ArrowForwardIcon iconStyle={classes.ctaIcon} />;
            }
        };

        const renderCopyBlocksAndCta = () => {
            if (!Array.isArray(data.reference[0].message_collection)) {
                return [];
            }
            const copyBlocks = data.reference[0].message_collection.map((item) => {
                if (item.copy) {
                    return (
                        <div className={classes.copy} style={generateValidCss(item.copy.styles, true)}>
                            <ReactMarkdown
                                source={item.copy.copy}
                                escapeHtml={false}
                            />
                        </div>
                    );
                }
                if (item.cta) {
                    return (
                        <div className={classes.cta} style={generateValidCss(item.cta.styles, true)}>
                            {item.cta.cta} <span className={classes.ctaIconContainer}>{renderCtaIcon(item.cta, classes)}</span>
                        </div>
                    );
                }
                return <></>;
            });
            return copyBlocks;
        };

        const calcImageWidth = () => {
            const msgWidth = generateValidCss(data.reference[0]?.message_styling, true)?.width;
            if (msgWidth.includes('%')) {
                return { width: `${100 - msgWidth.substring(0, msgWidth.indexOf('%'))}%` };
            }
            return { width: '55%' }; // default for design
        };

        const MobileT3Panel = () => (
            <div className={classes.heroContainerMobile}>
                <div className={classes.imageMobileContainer}>
                    <div className={classes.imageMobile} />
                    <div className={classes.imageBgPanelMobile} />
                </div>
                <div className={classes.messageContainerMobile}>
                    {renderCopyBlocksAndCta(classes)}
                </div>
            </div>
        );

        const DesktopT3Panel = () => (
            <div className={classes.heroContainer}>
                <div className={classes.image} style={calcImageWidth()} />
                <div className={classes.messageContainer}>
                    {renderCopyBlocksAndCta(classes)}
                </div>
            </div>
        );

        return (
            <Link
                to={data.reference[0].linking?.link?.href}
                title={data.reference[0].linking?.link?.title}
                data-ga-category={data.reference[0].linking?.tracking_event_category || ''}
                data-ga-action={data.reference[0].linking?.tracking_event_action || ''}
                data-ga-label={data.reference[0].linking?.tracking_event_label || ''}
                className={classes.link}
                aria-hidden="true"
                tabIndex="-1"
                onClick={() => {
                    track({
                        eventCategory: data.reference[0].linking?.tracking_event_category || '',
                        eventAction: data.reference[0].linking?.tracking_event_action || '',
                        eventLabel: data.reference[0].linking?.tracking_event_label || '',
                    });
                }}
            >
                <DesktopMobile
                    desktop={DesktopT3Panel}
                    mobile={MobileT3Panel}
                    breakpoint={690}
                />
            </Link>
        );
    }
    return <></>;
};

PanelT3.propTypes = {
    data: array.isRequired,
    track: func.isRequired,
};
PanelT3.defaultProps = {
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
);

export default enhance(PanelT3);
